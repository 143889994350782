.main-home {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 1em 2em !important;
    width: calc(100% - 270px);
    // width: calc(100% - 220px);
    transition: all 0.3s;
    // gap: 10px;

    .title-main-home {
        font-size: 20px;
        font-weight: bold;

        span {
            margin-left: 5px;
            font-size: 16px;
            font-weight: normal;
        }
    }

    .form-request,
    .form-forward {
        margin-top: 10px;
        width: 100%;

        .form-item label::before {
            content: '*';
            color: var(--red);
            padding-right: 4px;
            font-size: 14px;
            display: inline-block;
            line-height: 1;
            position: absolute;
            right: -5px;
        }

        .form-item label .ant-form-item-required::before {
            display: none;
        }

        .upload-file {
            display: flex;
            align-items: center;
            gap: 1em;
            margin-top: 10px;
            background-color: var(--black);
            color: var(--white);
            border-radius: 4px;
            padding: 10px;
            cursor: pointer;
        }

        .form-item-btn {

            .ant-form-item-control-input-content {
                margin-top: 10px;
                display: flex;
                justify-content: center;
                gap: 10px;
            }
        }
    }

    .table-request {
        width: 100%;
        padding: 10px 20px;
        background-color: white;
        border-radius: 6px;

        .title-table {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .ant-table-cell {
            text-align: center;
        }
    }

    .filter-contailer {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        background-color: white;
        padding: 20px;
        width: 100%;
        border-radius: 6px;
        margin-bottom: 10px;

        .lst-filter {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .lst-filter-item {
                display: flex;
                gap: 16px;  

                .filter-item {
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    .label{
                        white-space: nowrap;
                    }
                }
            }

           
        }

        .lst-btn-filter {
            display: flex;
            gap: 10px;

            .ant-btn {
                display: flex;
                align-items: center;

            }
        }
    }

}

.content-action {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    gap: 10px;
    width: 125px;

    .content-action-item {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 5px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            background-color: var(--gray);
        }
    }
}

.modal-view-request {
    .content-view-request {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .content-view-request-item {
            display: flex;
            gap: 10px;
            align-items: center;

            .content-view-request-item-label {
                font-weight: bold;
            }
        }
    }
}

.modal-add-expert {
    .ant-steps {
        margin-bottom: 20px;
    }

    .steps-action {
        display: flex;
        justify-content: end;
        // justify-content: space-between;
        gap: 10px;
    }
}

.ant-tag {
    white-space: unset;
}

.icon-table {
    display: flex;
    align-items: center;
}

.pending-approval {
    td {
        background: #f0f0f0 !important;
    }
}

.record-info {
    font-size: 14px;
    color: #333;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: inline-block;
    margin: 10px 0;

    span {
        color: #007bff;
        font-weight: bold;
    }
}

@media (max-width: 1200px) {
    .main-home {
        .filter-contailer {
            .lst-filter {
                .filter-item {
                    // flex: 1 1 calc(50% - 16px);

                    /* 2 items in a row */

                }
            }
        }
    }
}

@media (max-width: 600px) {
    .main-home {
        .filter-contailer {
            .lst-filter {
                .filter-item {
                    // flex: 1 1 100%;
                    /* 1 item in a row */
                }
            }
        }
    }
}