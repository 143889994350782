@import "./theme/colos.css";

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.form-item label::before {
    content: '*';
    color: var(--red);
    padding-right: 4px;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
    position: absolute;
    right: -5px;
}

.form-item label .ant-form-item-required::before {
    display: none;
}

.ant-modal {

    .ant-modal-content {
        // border-radius: 10px;

        .ant-modal-header {}

        .ant-modal-close {
            border: 1px solid var(--gray) !important;
        }

        .ant-modal-body {
            padding-top: 10px;
        }
    }

}
.button-primary {
    background-color: var(--black);
    color: var(--white);
    border: none;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        background-color: var(--black-hover) !important;
        opacity: 0.7;
    }
}

.ant-table-cell {
    text-align: center !important;
}
.ant-select-selection-placeholder {
    text-align: left;
}

// Change text antd select to left
.ant-select-selection-item {
    text-align: left;
}

.disabled-icon {
    cursor: not-allowed !important;
}
.row-inactive {
    background: var(--hover-gray) !important;
}

.ant-modal{
    .ant-modal-title{
        font-size: 20px;
    }
}

.ant-menu-item{
    padding: 0 !important;

    .ant-menu-title-content{
        display: flex;
        justify-content: start;
        padding-left: 25px;
    }


}

.menu-item-user-management{
    padding-left: 25px !important;
    .ant-menu-title-content{
        display: flex;
        justify-content: start;
        padding-left: 15px;
    }
}