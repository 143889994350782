.main-header {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2em;
    height: 4em;
    border-bottom: 2px solid rgba(5, 5, 5, 0.06);
    // box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    .main-header-left {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .content {
            display: flex;
            font-size: 1em;
            font-weight: bold;
            // margin-left: 1em;
            text-transform: uppercase;
        }


    }

    .main-header-right {
        display: flex;
        align-items: center;
        gap: 3em;

        .item-navbar {
            display: flex;
            align-items: center;
            gap: 0.5em;
            font-size: 1em;
            font-size-adjust: 0.58;
            color: var(--black);
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            padding-bottom: 10px;
            border-bottom: 2px solid transparent;

            &:hover {
                border-bottom: 2px solid var(--black);
            }
        }

        .item-navbar.active {
            font-weight: bold;
            border-bottom: 2px solid var(--black);
        }

        .btn-logout {
            display: flex;
            align-items: center;
            gap: 0.5em;
            font-size: 1em;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            background-color: var(--black);
            background: var(--black);

            &:hover {
                opacity: 0.8;
            }
        }

        .avatar{
            display: flex;
            align-items: center;
            gap: 10px;
            border-radius: 6px;
            padding: 5px 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: rgba(151, 151, 151, 0.1);
            }
        }
    }
}