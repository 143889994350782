.manage-user-history {
    padding: 24px;
    // background-color: #f0f2f5;
    width: 100%;
    display: flex;
    flex-direction: column;

    .back-button {
        margin-bottom: 20px;
        width: 100px;
    }

    .page-title {
        font-size: 24px;
        font-weight: bold;
        // margin-bottom: 24px;
        color: #262626;
    }

    .user-info {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        h2 {
            font-size: 18px;
            margin-bottom: 16px;
            color: #262626;
        }

        .info-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 16px;

            .info-item {
                p {
                    margin: 0;

                    &.label {
                        font-weight: bold;
                        color: #8c8c8c;
                    }

                    &.value {
                        color: #262626;
                    }
                }
            }
        }
    }

    // .history-table {
    //     background-color: white;
    //     padding: 24px;
    //     border-radius: 8px;
    //     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    //     .ant-table-thead>tr>th {
    //         background-color: #f0f2f5;
    //         color: #262626;
    //     }

    //     // .ant-table-tbody>tr:hover>td {
    //     //     background-color: #e6f7ff;
    //     // }
    // }
}

.main-home {
    padding: 20px;

    .title-home {
        margin-bottom: 20px;

        h2 {
            font-size: 24px;
            font-weight: bold;
        }
    }

    .user-info-container {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 20px;
        width: 100%;
        padding-top: 0px;
        h3 {
            font-size: 18px;
        }

        .user-info-grid {
            display: flex;
            justify-content: space-between; 
            gap: 15px;

            .info-item {
                display: flex;
                flex-direction: column;
                align-items: center;


                .label {
                    font-weight: bold;
                    margin-right: 10px;
                }

                .value {
                    color: #1890ff;
                }
            }
        }
    }

    .table-request {
        background-color: white;
        padding: 20px;
        border-radius: 8px;

        .title-table {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            h2 {
                font-size: 20px;
                margin: 0;
            }
        }
    }
}