.site-layout {
    background-color: var(--white);
    background: var(--white);
    position: relative;
    border-right: 2px solid rgba(5, 5, 5, 0.06);
    background-color: transparent;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);


    .button-sider {
        position: absolute;
        inset-block-start: 20px;
        z-index: 101;
        width: 24px;
        height: 24px;
        text-align: center;
        border-radius: 40px;
        inset-inline-end: -13px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(25, 15, 15, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
        transition: color 0.3s, background-color 0.3s;

        &:hover {
            color: rgba(0, 0, 0, 0.45);
        }

    }
}

.ant-layout .ant-layout-sider {
    background-color: var(--white);
    background: var(--white);

    .ant-layout-sider-children {
        background-color: var(--white);
        background: var(--white);
    }
}

.ant-layout-sider-trigger {
    display: none;
}