.main-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--blue);
    padding: 1em;

    .main-login-container {
        background-color: var(--white);
        display: flex;
        width: 50%;
        height: 50%;
        border-radius: 10px;
        margin: 2em;
        background-image: url('../../asset/images/bg-login.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;


        .main-login-container-left {
            background-color: var(--white);
            width: 50%;
            border-radius: 10px 0 0 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}
