:root {
    --black: #1D1D1F;
    --black-hover: #0A0A0A;
    --white: #FFFFFF;
    --red: #ff4d4f;
    --blue: #526296;
    --gray: #f0f0f0;
    --hover-gray: #fafafa;
    --orange: #ed7d31;
    --primary: #4096ff;
}